/* A */
.artist-card {
    border-radius: 8px;
    overflow: hidden;
    height: 54vh;
    width: 25vw
}

.artist-card-image {
    object-fit: cover;
    height: 45vh;
    width: 100%;
}

.artist-card-info {
    background-color: rgb(49, 51, 56);
    height: 9vh;
    padding: 16px
}

/* B */

.bg-image {
    overflow: hidden;
    filter: blur(6px);
    -webkit-filter: blur(6px);

    height: 100vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.background-image {
    height: 100vh;
    object-fit: cover;
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.background-image-container {
    overflow: hidden;
    position: fixed;
}

.button-container {
    text-align: center;
    width: 50%;
    margin: 10px 0;
}

/* D */
.dark-button {
    background-color: rgb(54, 56, 64)!important;
    border: none!important;
    color: white !important;
    font-font: 'Comfortaa';
    box-shadow: none;
}

.dark-button:hover {
    background-color: rgb(76, 80, 92)!important;
}

/* F */
.fade-enter, .fade-exit {
    transition: 1000ms ease opacity;
}

.fade-enter, .fade-exit-active {
    opacity: 0;
}
.fade-exit-active {
    display: none;
}
.fade-enter-active {
    opacity: 1;
}

/* H */

.home-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
}

/* N */
.no-padding {
    padding: 0 !important;
}
/* S */
.slide-enter,
.slide-exit {
    transition: transform 1000ms ease-out;
}

.slide-enter {
    transform: translateX(100%);
}
.slide-enter.slide-enter-active {
    transform: translateX(0%);
}
.slide-exit {
    transform: translateX(0%);
}
.slide-exit-active {
    transform: translateX(-100%);
}

/* V */

.vertical-center-container {
    height: 100%;
    position: relative;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}